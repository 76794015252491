import gql from "graphql-tag"

export const GET_PRODUCTS = gql`
  query products($first: Int, $last: Int, $query: String!, $afterCursor: String, $beforeCursor: String, $reverse: Boolean) {
    products(first: $first, last: $last, query: $query, after: $afterCursor, before: $beforeCursor, reverse: $reverse, sortKey: UPDATED_AT) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          createdAt
          description
          descriptionHtml
          id
          handle
          tags
          title
          updatedAt
          onlineStoreUrl
        }
      }
    }
  }
`

export const UPDATE_PRODUCT_TAGS = gql`
  mutation productUpdate($input: ProductInput!) {
    productUpdate(input: $input) {
      product {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`

export const GET_DRAFT_ORDERS = gql`
  query draftOrders($first: Int, $last: Int, $query: String!, $afterCursor: String, $beforeCursor: String, $reverse: Boolean) {
    draftOrders(first: $first, last: $last, query: $query, after: $afterCursor, before: $beforeCursor, reverse: $reverse, sortKey: UPDATED_AT) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          createdAt
          id
          tags
          updatedAt
          invoiceUrl
          invoiceSentAt
          ready
          appliedDiscount {
            value
          }
          subtotalPrice
          totalShippingPrice
          totalPrice
          totalTax
          status
          name
          customer {
            id
            email
            displayName
          }
          shippingAddress {
            formatted(withName: true)
          }
          lineItems(first: 15) {
            edges {
              node {
                id
                title
                quantity
                customAttributes {
                  key
                  value
                }
                product {
                  id
                  tags
                }
                variant {
                  id
                  displayName
                  image {
                    originalSrc
                  }
                  selectedOptions {
                    name
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_DRAFT_ORDER = gql`
  mutation draftOrderUpdate($id: ID!, $input: DraftOrderInput!) {
    draftOrderUpdate(id: $id, input: $input) {
      draftOrder {
        createdAt
        id
        tags
        updatedAt
        invoiceUrl
        invoiceSentAt
        ready
        appliedDiscount {
          value
        }
        subtotalPrice
        totalShippingPrice
        totalPrice
        totalTax
        status
        name
        customer {
          id
          email
          displayName
        }
        shippingAddress {
          formatted(withName: true)
        }
        lineItems(first: 15) {
          edges {
            node {
              id
              title
              quantity
              customAttributes {
                key
                value
              }
              product {
                id
                tags
              }
              variant {
                id
                displayName
                image {
                  originalSrc
                }
                selectedOptions {
                  name
                  value
                }
              }
            }
          }
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`

export const SEND_DRAFT_ORDER = gql`
  mutation draftOrderInvoiceSend($id: ID!, $email: EmailInput) {
    draftOrderInvoiceSend(id: $id, email: $email) {
      draftOrder {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`
